//Colors
$white-color: #ffffff;
$black-color: #212529;
$disabled-gray-color: #c6c6c6;
$transparent: transparent;

//Brand colors
$brand-primary: #f0ab99;
$brand-secondary: #124276;
$brand-secondary-2: #306db1;
$brand-secondary-3: #bf3939;
$brand-secondary-4: #f4dc6a;
$brand-secondary-5: #bf3939;
$brand-golden: #ffd9c9;

$brand-tertiary: #b0b0b0;
$brand-footer: #ecf2ff;
$brand-modal-overlay: rgba(255, 255, 255, 0.3);
$brand-disabled-gray: #c6c6c6;
$brand-white: #ffffff;
$brand-peach: #ffefe9;
$brand-blue: #3396ff;
$brand-navigation: #e5f0f5;
$brand-scroll: #787878;
$brand-grey: #a0a0a0;
$brand-yellow: #ffe744;
$brand-divider: #d5d5d5;
$brand-navigation-divider: #636466;
$brand-hero-button-primary: #50a4ff;
$brand-hero-button-secondary: #b0d6ff;
$brand-shadow: #2c296d1c;
$brand-border-color: #eaf3fe;
$brand-scroll-track: #f0f0f0;
$brand-scroll-thumb: #787878;
$brand-modal-title: #dbedff;
$brand-taupe-light: #f7f5f3;
$brand-slate: #265472;
$brand-footer-links: #1b4298;
$brand-bg-healthcare: #ebf4fd;

// Variations
$brand-coral-dark: #e71d13;
$brand-coral-light: #f7aeaa;
$brand-slate-light: #e5f0f5;

// Tints
$brand-primary-tints-100: #fff3ef;
$brand-primary-tints-200: #f9ddd6;
$brand-primary-tints-300: #f6cdc2;
$brand-primary-tints-400: #f3bcad;
$brand-primary-tints-500: #f0ab99;
$brand-primary-tints-600: #c0897a;
$brand-primary-tints-800: #60443d;
$brand-primary-tints-accordion: #fff3ef;

$brand-secondary-tints-100: #d0d9e4;
$brand-secondary-tints-200: #a0b3c8;
$brand-secondary-tints-300: #718ead;
$brand-secondary-tints-400: #416891;
$brand-secondary-tints-500: #124276;

$brand-secondary-2-tints-100: #e3f0ff;
$brand-secondary-2-tints-200: #acc5e0;
$brand-secondary-2-tints-300: #83a7d0;
$brand-secondary-2-tints-400: #598ac1;
$brand-secondary-2-tints-500: #306db1;

$brand-secondary-3-tints-90: #fdf3f0;
$brand-secondary-3-tints-100: #f5dcd6;
$brand-secondary-3-tints-200: #ebb8ad;
$brand-secondary-3-tints-300: #e29583;
$brand-secondary-3-tints-400: #d8715a;
$brand-secondary-3-tints-500: #bf3939;

$brand-secondary-4-tints-100: #fbf4d2;
$brand-secondary-5-tints-100: #e5ad9b;
$brand-slate-dark: mix(#000, #265472, 20%);

//Text colors
$text-color-primary: #1c1919;
$text-color-secondary: #ffffff;
$text-color-tertiary: #151213;
$text-color-alt: #444444;
$text-color-link: #124276;
$text-color-link-hover: #40a9ff;
$text-color-disabled: #858585;
$text-color-graphics: #333333;
$text-color-utulity-hover: #841e1e;

//Grhapics
$brand-graphic-blue: #d7e9fa;
$brand-uterus-graphic-blue: #77abdb;
$brand-uterus-graphic-yellow: #efdf7b;
$brand-uterus-graphic-red: #c1573b;
$brand-pdf-divider: #434648;

// Typography
$font-family-default: var(--font-montserrat);
$font-family-secondary: 'Montserrat', sans-serif;

//Font weights
$font-weight-light: 300;
$font-weight-normal: 400; //Roman
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;
$font-weight-black: 900;

// Font stretchs
$font-stretch-extended: expanded;
$font-stretch-condensed: condensed;

//Font sizes
$font-size-xxxxs: 0.5625rem; //9px
$font-size-xxxs: 0.625rem; //10px
$font-size-xxs: 0.6875rem; //11px
$font-size-xs: 0.75rem; //12px
$font-size-sm: 0.875rem; //14px
$font-size-md: 1rem; //16px
$font-size-lg: 1.125rem; //18px
$font-size-xl: 1.25rem; //20px
$font-size-2xl: 1.375rem; //22px
$font-size-3xl: 1.5rem; //24px
$font-size-4xl: 1.5rem; //24x
$font-size-5xl: 2.1875rem; //35px
$font-size-6xl: 2.25rem; //36px
$font-size-7xl: 3.25rem; //52px
$font-size-navigation: 1.063rem; //17px
$font-size-mobile-navigation: 1.313rem; //21px
$font-size-graphic-title: 1.875rem; //21px
$font-size-subtitle-social: 1.625rem; //26px

// Timing functions
$timing-elastic: 400ms cubic-bezier(0.25, 1, 0.5, 1);

// Shadows
$isi-bar-shadow: 0px -8px 16px 8px rgba(28, 25, 25, 0.09);

$deep-drop-shadow: 0 20px 50px rgba(#2b2b2b, 0.1);
$medium-drop-shadow: 0px 10px 18px rgba(#2b2b2b, 0.06);
$shallow-drop-shadow: 0px 4px 10px rgba(#2b2b2b, 0.1);
$navigation-drop-shadow: 0px 2px 14px rgba(43, 43, 43, 0.1);
$nurse-navigator-drop-shadow: 0px 4px 10px 3px rgba(#2b2b2b, 0.1);

@mixin noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Old version of Firefox */
  -ms-user-select: none;
  /* Internet Explorer or Edge */
  user-select: none;
  /* All modern browsers */
}

$navbar-height: 5rem;

// Breakpoints
$breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1720px,
);

$container-max-widths: (
  xs: 460px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1184px,
  xxl: 1200px,
  xxxl: 1640px,
);

$container-small-max-widths: (
  xs: 460px,
  sm: 540px,
  md: 720px,
  lg: 896px,
  xl: 896px,
  xxl: 896px,
  xxxl: 896px,
);

// Radius
$default-border-radius: 10px;

// Grid
$grid-columns: 12;
$gutter-sizes: 5, 10, 15, 20, 25, 30, 35, 40;
$container-padding: 24px;

// Component Spacing Defaults
$default-padding: 1rem;
$default-line-height: 1.375;
$default-button-height: 3.375rem;

// Colors Variants
$light-color-variants: (
  primaryLight: $brand-primary-tints-100,
  secondaryLight: $brand-secondary-tints-100,
  secondary2Light: $brand-secondary-2-tints-100,
  secondary3Light: $brand-secondary-3-tints-100,
  secondary4Light: $brand-secondary-4-tints-100,
);

$custom-nav-breakpoint: 1300px;
$custom-nav-items-breakpoint: 1300px;
