@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'styles/containers.scss';

.heroLeft {
  width: 100%;

  @include breakpoint(lg, min) {
    width: 50%;
  }
}

.heroRight {
  position: relative;
  width: 100%;
  padding-bottom: 58.6%;

  @include breakpoint(md, min) {
    padding-bottom: 45%;
  }

  @include breakpoint(lg, min) {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    padding-bottom: 0;
    position: absolute;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}

.heroContent {
  [class*='_richText_'] h2 {
    margin-bottom: 16px;
  }
}

.heroChildren {
  [class*='_button_'] {
    margin-top: 24px;
  }
}

.heroMainTitle {
  @include h4-eyebrow;
  color: $brand-secondary-3;
  margin-bottom: 16px;

  :global {
    span.semibold {
      font-weight: $font-weight-semibold;
    }
  }
}

.heroImage {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  img {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    @include caption;
    position: absolute;
  }
}

.hero {
  position: relative;
  overflow: hidden;
  min-height: min(560px, calc(100vh - 125px));
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column-reverse;

  @include breakpoint(md, min) {
    min-height: min(640px, calc(100vh - 113px));
  }

  @include breakpoint(lg, min) {
    min-height: clamp(580px, calc(100vh - 113px), 865px);
    justify-content: space-between;
    flex-direction: row;
  }

  @include breakpoint(xl, min) {
    min-height: clamp(580px, calc(100vh - 103px), 865px);
  }

  &.noBackground .heroRight:before {
    background: transparent;
  }

  // Backgrounds
  @each $variant-name, $color in $light-color-variants {
    &.#{$variant-name} .heroRight:before {
      background: $color;
    }
  }

  // Variants
  &.cover {
    .heroLeft {
      padding: 24px $container-padding;
      @include make-padder-left;

      @include breakpoint(md, min) {
        padding-top: 48px;
        padding-bottom: 48px;
        padding-right: 48px;
      }

      @include breakpoint(lg, max) {
        @include make-padder-right;
      }
    }

    .heroImage {
      padding: 0 48px;
      align-items: center;
      justify-content: center;
    }

    figcaption {
      background: $brand-golden;
      padding: 4px $container-padding 4px 10px;
      bottom: 0;
      right: 0;

      @include breakpoint(md, min) {
        padding: 12px 16px;
      }
    }
  }

  &.overlap {
    .heroImage {
      padding: 16px $container-padding 0 $container-padding;
      align-items: center;
      justify-content: center;

      @include make-padder-left;
      @include make-padder-right;

      @include breakpoint(md, min) {
        padding-top: 32px;
      }

      @include breakpoint-range(lg, xl) {
        padding-left: 80px;
        padding-right: 80px;
      }

      img {
        @include breakpoint(lg, max) {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          object-position: center bottom;
        }
      }
    }

    .heroRight::before {
      height: max(calc(100% - 21%), 170px);

      @include breakpoint(lg, min) {
        height: 100%;
      }
    }

    .heroLeft {
      padding: 16px $container-padding 24px;
      @include make-padder-left;

      @include breakpoint(md, min) {
        padding-top: 48px;
        padding-bottom: 48px;
        padding-right: 48px;
      }

      @include breakpoint(lg, max) {
        @include make-padder-right;
      }
    }
  }

  &.main,
  &.mainSpanish {
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 25px;

    @include breakpoint(md, min) {
      margin-bottom: 0;
    }

    @include breakpoint(lg, max) {
      flex-direction: column;
    }

    .heroMainTitle {
      @include h3;
      font-variant: normal;
    }

    .heroImage {
      bottom: 0;
      top: auto;
      overflow: hidden;
      height: calc(100% - 25px);
      z-index: 100;

      @media (max-aspect-ratio: 70/49) {
        height: calc(100% - 80px);
      }

      @media (max-aspect-ratio: 5/4) {
        height: calc(100% - 200px);
      }

      @include breakpoint(xl, max) {
        height: 85%;
      }

      @include breakpoint(1099px, max) {
        height: 80%;
      }

      @include breakpoint(lg, max) {
        height: 100%;
        width: 100%;
      }

      img {
        max-width: none;
        width: auto;
        margin-left: auto;

        @include breakpoint(lg, min) {
          margin-left: 0;
        }

        height: 100%;
        width: auto;
      }
    }

    .heroContent {
      position: relative;
      height: 100%;
    }

    .heroChildren {
      h2 {
        @include h1;

        span {
          display: inline-block;
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 30px;
            background: $brand-secondary-2-tints-100;
            z-index: -1;
            bottom: -7px;
            left: 0;
            position: absolute;
          }
        }
      }

      [class*='Button_primary_'] {
        margin: 24px 0;

        @include breakpoint(sm, min) {
          margin: 32px 0 42px;
        }
      }

      h3 {
        @include h4;
      }

      p {
        @include body;
      }

      [class*='_outlineSecondary_'] {
        margin: 16px 0 0;
      }

      [class*='Button_simple_'] {
        margin-top: 0;
        position: relative;
        bottom: 0;
        left: 0;

        @include breakpoint(lg, min) {
          margin-top: 30px;
        }

        @include breakpoint(lg, min) {
          position: absolute;
          margin-top: 0;
        }

        @media (min-width: 1199.5px) and (max-height: 790px) {
          position: relative;
          margin: 50px 0 10px;
        }

        @include breakpoint-range(lg, xl) {
          position: relative;
          margin: 40px 0 0;
        }
      }

      h3,
      h3 ~ p {
        width: 100%;
      }

      h3 ~ p {
        @include breakpoint(md, max) {
          width: calc(100% - 150px);
        }

        @include breakpoint-range(lg, xl) {
          width: calc(100% - 150px);
        }
      }
    }

    .heroLeft {
      padding: 24px $container-padding;
      position: relative;
      @include make-padder-left;

      @include breakpoint(lg, min) {
        width: 60%;
        padding-right: 10%;
        padding-top: 72px;
        padding-bottom: min(172px, 8%);
      }

      @include breakpoint(lg, max) {
        @include make-padder-right;
      }

      @include breakpoint(440px, max) {
        padding-bottom: 114px;
      }
    }

    .heroRight {
      z-index: -1;
      margin-top: -140px;

      @include breakpoint(lg, min) {
        margin-top: -120px;
      }

      @include breakpoint(lg, min) {
        margin-top: 0;
        width: 60%;
      }

      @include breakpoint(440px, max) {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;
        height: 268px;
      }

      &::before {
        width: 66.67%;
        right: 0;
        left: auto;
        display: none;

        @include breakpoint(lg, min) {
          display: block;
        }
      }
    }
  }

  &.mainSpanish {
    .heroRight {
      @include breakpoint(lg, min) {
        width: 45%;
      }

      &::before {
        display: block;
        width: 100%;
        height: 90px;
        bottom: 0;

        @include breakpoint(lg, min) {
          width: 88%;
          height: 100%;
        }
      }

      .heroImage {
        @include breakpoint(xs, max) {
          left: 5px;
        }
      }
    }

    .heroChildren {
      h3 ~ p {
        @include breakpoint(md, max) {
          width: calc(100% - 200px);
        }

        @include breakpoint(xs, max) {
          width: calc(100% - 180px);
        }

        @include breakpoint-range(md, lg) {
          width: calc(100% - 220px);
        }

        @include breakpoint-range(lg, xl) {
          width: 100%;
        }
      }
    }
  }
}
