@import 'styles/breakpoints';

@mixin h1-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-7xl;
  line-height: 1.04;
}

@mixin h2-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-5xl;
  line-height: 1.11;
}

@mixin h3-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-medium;
  font-size: $font-size-xl;
  line-height: 1.5;
}

@mixin h4-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-semibold;
  font-size: $font-size-lg;
  line-height: 1.22;
  letter-spacing: 0.02em;
  font-variant: all-small-caps;
}

@mixin h5-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-xl;
  line-height: 1.4;
}

@mixin intro-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-light;
  font-size: $font-size-3xl;
  line-height: 1.3333;
}

@mixin body-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-light;
  font-size: $font-size-xl;
  line-height: 1.25;
}

@mixin caption-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  line-height: 1.29;
}

@mixin h5-mobile {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-xl;
  line-height: 1.4;
  letter-spacing: -0.014em;
}

@mixin h1 {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-6xl;
  line-height: 1.03;

  @include breakpoint(md, min) {
    @include h1-desktop;
  }
}

@mixin h2 {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-4xl;
  line-height: 1.14;

  @include breakpoint(md, min) {
    @include h2-desktop;
  }
}

@mixin h3 {
  font-family: $font-family-default;
  font-weight: $font-weight-medium;
  font-size: $font-size-lg;
  line-height: 1.44;

  @include breakpoint(md, min) {
    @include h3-desktop;
  }
}

@mixin h4 {
  font-family: $font-family-default;
  font-weight: $font-weight-semibold;
  font-size: $font-size-sm;
  line-height: 1.44;
  font-variant: normal;
  letter-spacing: -0.014em;

  @include breakpoint(md, min) {
    @include h4-desktop;
  }
}

@mixin h5 {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-xl;
  line-height: 1.4;

  @include breakpoint(md, min) {
    font-size: $font-size-lg;
    line-height: 1.333;
  }
}

@mixin intro {
  font-family: $font-family-default;
  font-weight: $font-weight-light;
  font-size: $font-size-md;
  line-height: 1.25;

  @include breakpoint(md, min) {
    @include intro-desktop;
  }
}

@mixin body {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  line-height: 1.29;

  @include breakpoint(md, min) {
    @include body-desktop;
  }
}

@mixin caption {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-xxs;
  line-height: 1.64;

  @include breakpoint(md, min) {
    @include caption-desktop;
  }
}

@mixin caption-small {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-xs;
  line-height: 1.5;
}

@mixin button {
  font-family: $font-family-default;
  font-weight: $font-weight-medium;
  font-size: $font-size-md;
  line-height: 1.18;
}

@mixin logo {
  font-family: $font-family-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-5xl;
  color: $brand-primary;
  line-height: 1.256;
}

@mixin top-navigation-list {
  font-family: $font-family-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
  color: $brand-slate;
  line-height: 1.375;

  @media (max-width: 390px) {
    font-size: $font-size-sm;
  }
}

@mixin top-navigation {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-md;
  color: $text-color-primary;
  line-height: 1.5;

  @media (max-width: 1270px) {
    font-size: $font-size-sm;
  }
}

@mixin main-top-navigation {
  font-family: $font-family-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  color: $brand-secondary-3-tints-500;
  text-decoration: none;
  line-height: 1.571;

  @include breakpoint(xl, min) {
    font-size: $font-size-sm;
  }
}

@mixin stick-navigation {
  font-family: $font-family-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  color: $brand-secondary-3;
  text-decoration: none;
  line-height: $font-size-lg;
  text-align: center;
}

@mixin mobile-navigation {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-mobile-navigation;
  color: $text-color-primary;
  text-decoration: none;
  line-height: 1.28;
  text-align: left;
  letter-spacing: -0.014em;
}

@mixin main-navigation {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-md;
  color: $text-color-primary;
  line-height: 1.5;
}

@mixin dropdown {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  color: $brand-white;
  line-height: 1.5;

  @include breakpoint(xl, min) {
    font-size: $font-size-md;
  }
}

@mixin footer {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-xs;
  color: $black-color;
  line-height: 1.66;

  @include breakpoint(md, min) {
    line-height: 2;
  }
}

@mixin footer-title-list {
  font-family: $font-family-default;
  font-weight: $font-weight-medium;
  font-size: $font-size-md;
  color: $text-color-primary;
  line-height: 1.187;
}

@mixin footer-navigation-list {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  color: $text-color-primary;
  line-height: 1.571;
}

@mixin accordion-title {
  font-family: $font-family-default;
  color: $brand-primary;
  font-weight: $font-weight-bold;
  font-size: $font-size-xl;
  line-height: 1.4;
}

@mixin title-banner {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-xl;
  line-height: 1.25;
}

@mixin primary-links {
  font-family: $font-family-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
  line-height: 1.25;
  letter-spacing: -0.014em;
}

@mixin small {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-xs;
  color: $text-color-primary;
  line-height: 1.5;
}

@mixin isi-desktop {
  font-family: $font-family-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-xl;
  color: $text-color-primary;
  line-height: 1.3;
}

@mixin h3-modal {
  font-family: $font-family-default;
  font-weight: $font-weight-semibold;
  font-size: $font-size-xl;
  color: $text-color-primary;
  line-height: 1.2;
}

@mixin graphic-title {
  font-family: $font-family-default;
  font-weight: $font-weight-heavy;
  font-size: 13px;
  color: $text-color-graphics;
  line-height: 1.5;

  @include breakpoint(md, min) {
    font-size: $font-size-graphic-title;
  }
}

@mixin graphic-subtitle {
  font-family: $font-family-default;
  font-weight: $font-weight-light;
  font-size: 8.69px;
  color: $text-color-graphics;
  line-height: 1.44;

  @include breakpoint(md, min) {
    font-size: $font-size-xl;
  }
}

@mixin graphic-body-title {
  font-family: $font-family-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
  color: $text-color-graphics;
  line-height: 1.043;

  @include breakpoint(md, min) {
    font-size: $font-size-xl;
    line-height: 1.44;
  }
}

@mixin graphic-body {
  font-family: $font-family-default;
  font-weight: $font-weight-light;
  font-size: $font-size-xs;
  color: $text-color-graphics;
  line-height: 1.043;

  @include breakpoint(md, min) {
    font-size: $font-size-xl;
    line-height: 1.44;
  }
}

@mixin h1-desktop-h2-mobile {
  @include h2;

  @include breakpoint(md, min) {
    @include h1-desktop;
  }
}

@mixin intro-large {
  @include intro;
  font-size: $font-size-lg;

  @include breakpoint(lg, min) {
    font-size: $font-size-xl;
  }
}

@mixin h4-eyebrow {
  font-family: $font-family-default;
  font-weight: $font-weight-semibold;
  font-size: $font-size-md;
  line-height: 1.44;
  font-variant: normal;
  letter-spacing: -0.014em;

  @include breakpoint(md, min) {
    font-family: $font-family-default;
    font-weight: $font-weight-semibold;
    font-size: $font-size-xl;
    line-height: 1.22;
    letter-spacing: 0.02em;
    font-variant: all-small-caps;
  }
}

@mixin social-paragraph {
  font-family: $font-family-default;
  font-weight: $font-weight-light;
  font-size: $font-size-lg;
  line-height: 1.22;
  color: $black-color;

  @include breakpoint(md, min) {
    font-size: $font-size-subtitle-social;
    line-height: 1.076;
  }
}

@mixin body-hero-enrollment {
  font-family: $font-family-default;
  font-weight: $font-weight-medium;
  font-size: $font-size-xl;
  line-height: 1.5;
}

@mixin desktop-card {
  font-family: $font-family-default;
  font-weight: $font-weight-normal;
  font-size: $font-size-md;
  color: $brand-primary-tints-800;
  line-height: 1.375;
}
