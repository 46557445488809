@import './variables.scss';

@mixin breakpoint($breakpoint, $direction: min) {
  $breakpoint-value: get-breakpoint-value($breakpoint);

  @if $direction==max {
    @media (max-width: ($breakpoint-value - 0.02)) {
      @content;
    }
  } @else if $direction==min {
    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
}

@mixin breakpoint-range($breakpoint-min, $breakpoint-max) {
  $breakpoint-min-value: get-breakpoint-value($breakpoint-min);
  $breakpoint-max-value: get-breakpoint-value($breakpoint-max);

  @media (min-width: $breakpoint-min-value) and (max-width: ($breakpoint-max-value - 0.02)) {
    @content;
  }
}

@function get-breakpoint-value($breakpoint) {
  // If the breakpoint doesn't exist, just use the passed value (i.e 500px)
  @if map-has-key($breakpoints, $breakpoint) {
    @return map-get($breakpoints, $breakpoint);
  } @else {
    @return $breakpoint;
  }
}
