@import 'styles/variables.scss';

@mixin resetButtonStyles {
  text-decoration: none;
  -webkit-appearance: none;
  cursor: pointer;
  border: 0;
  background: transparent;
  color: inherit;
}

@mixin primary {
  background: $brand-primary;

  &:hover,
  &:focus-visible {
    background: transparent;
    color: $brand-secondary;
    border-color: $brand-secondary;
  }

  &:active {
    background: $brand-secondary;
    color: $brand-primary;
    border-color: $brand-primary;
  }
}

@mixin secondary {
  background: $brand-secondary;
  color: $brand-white;

  &:hover,
  &:focus-visible {
    background: transparent;
    color: $brand-secondary;
    border-color: $brand-secondary;
  }

  &:active {
    background: $brand-secondary;
    color: $brand-primary;
    border-color: $brand-primary;
  }
}

@mixin secondary2 {
  background: $brand-secondary-2;
  color: $brand-white;

  &:hover,
  &:focus-visible {
    background: transparent;
    color: $brand-secondary-2;
    border-color: $brand-secondary-2;
  }

  &:active {
    background: $brand-secondary-2;
    color: $brand-primary;
    border-color: $brand-primary;
  }
}

@mixin secondary4 {
  background: $brand-secondary-4;

  &:hover,
  &:focus-visible {
    background: transparent;
    color: $brand-secondary;
    border-color: $brand-secondary;
  }

  &:active {
    background: $brand-secondary;
    color: $brand-primary;
    border-color: $brand-primary;
  }
}

@mixin outlineSecondary {
  background: $white-color;
  border-color: $brand-secondary;
  color: $brand-secondary;

  &:hover,
  &:focus-visible {
    background: $brand-secondary;
    color: $brand-white;
    border-color: $brand-secondary;
  }

  &:active {
    background: $brand-secondary;
    color: $brand-primary;
    border-color: $brand-primary;
  }
}

@mixin outlineSecondary2 {
  background: transparent;
  border-color: $brand-secondary-2;
  color: $brand-secondary-2;

  &:hover,
  &:focus-visible {
    background: $brand-secondary-2;
    color: $brand-white;
    border-color: $brand-secondary-2;
  }

  &:active {
    background: $brand-secondary-2;
    color: $brand-primary;
    border-color: $brand-primary;
  }
}

@mixin primaryLight {
  background: $brand-white;

  &:hover,
  &:focus-visible {
    background: transparent;
    color: $brand-white;
    border-color: $brand-white;
  }

  &:active {
    background: $brand-primary-tints-800;
  }
}

@mixin primaryLight2 {
  background: $brand-white;

  &:hover,
  &:focus-visible {
    background: transparent;
    color: $text-color-primary;
    border-color: $text-color-primary;
    border-width: 1.5px;

    svg {
      fill: $text-color-primary;
    }
  }

  svg {
    fill: $brand-primary;
    margin-right: 6px;
  }
}

@mixin secondaryLight {
  border-color: $brand-white;
  color: $brand-white;

  &:hover,
  &:focus-visible {
    background-color: $brand-white;
    color: $text-color-primary;
  }

  &:active {
    background: $brand-primary-tints-800;
    color: $brand-white;
  }
}

@mixin resetButtonWrapper {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
}
