@import 'Nori/Button/mixin.scss';
@import 'styles/breakpoints.scss';
@import 'styles/containers.scss';
@import 'styles/typography.scss';

.actionStrip {
  background: $brand-secondary-2-tints-100;
  padding: 8px 0;
}

.actionStripTitle {
  @include h4;
  line-height: 1.14;
  text-align: center;

  @include breakpoint(md, min) {
    text-align: left;
  }
}

.actionStripContent {
  flex: 1;
}

.actionStripContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  flex-direction: column;

  @include breakpoint(md, min) {
    flex-direction: row;
  }
}
