@use 'sass:math';

@mixin make-container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding;
  padding-right: $container-padding;
}

@mixin make-padder-left($offset: 0px) {
  $breakpoints: sm, md, lg, xl, xxl;
  @each $breakpoint in $breakpoints {
    @include breakpoint($breakpoint) {
      $w: math.div(map-get($container-max-widths, $breakpoint), 2);
      padding-left: calc(50% - $w + $container-padding + $offset);
    }
  }
}

@mixin make-padder-right($offset: 0px) {
  $breakpoints: sm, md, lg, xl, xxl;
  @each $breakpoint in $breakpoints {
    @include breakpoint($breakpoint) {
      $w: math.div(map-get($container-max-widths, $breakpoint), 2);
      padding-right: calc(50% - $w + $container-padding + $offset);
    }
  }
}

@mixin container {
  $breakpoints: sm, md, lg, xl, xxl;
  @include make-container;

  @each $breakpoint in $breakpoints {
    @include breakpoint($breakpoint) {
      max-width: map-get($container-max-widths, $breakpoint);
    }
  }
}

@mixin container-no-padding {
  $breakpoints: sm, md, lg, xl, xxl;
  width: 100%;
  max-width: 100%;

  @each $breakpoint in $breakpoints {
    @include breakpoint($breakpoint) {
      max-width: map-get($container-max-widths, $breakpoint) -
        $container-padding * 2;
    }
  }
}

@mixin containerLarge {
  $breakpoints: sm, md, lg, xl, xxl, xxxl;
  @include make-container;

  @each $breakpoint in $breakpoints {
    @include breakpoint($breakpoint) {
      max-width: map-get($container-max-widths, $breakpoint);
    }
  }
}

@mixin containerFluid {
  @include make-container;
}

@mixin containerXL {
  @include make-container;
}
