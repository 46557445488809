@import 'Nori/Button/mixin.scss';
@import 'styles/breakpoints.scss';
@import 'styles/containers.scss';
@import 'styles/typography.scss';

.finderSimple {
  background: $brand-secondary-3-tints-100;
  padding: 40px 0;
}

.finderTitle {
  @include h4-desktop;
  margin-bottom: 8px;
}

.finderCopy {
  @include intro-desktop;

  @include breakpoint(md, min) {
    line-height: 1;
  }
}

.finderContent {
  flex: 1;
}

.finderContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 40px;
  row-gap: 40px;
  flex-direction: column;

  @include breakpoint(md, min) {
    flex-direction: row;
    align-items: center;
  }
}

.finderSimple a.finderButton {
  flex: none;
  @include primaryLight;

  &:hover {
    color: $brand-secondary;
    border-color: $brand-secondary;
  }

  &:active {
    border-color: $brand-white;
    color: $brand-white;
  }
}
